<div class="modal-header">
  <button
    [appFocusable]
    type="button"
    class="close-btn"
    aria-label="Close"
    (click)="activeModal.dismiss()"
  >
    <img
      appAssetSrc="assets/svg/common/cross.svg"
      alt="{{ 'common.buttons.close' | translate }}"
    />
  </button>
</div>

<div class="modal-body">
  <h1>{{ 'workflowErrorsModal.title' | translate }}</h1>

  <div>{{ 'workflowErrorsModal.thereAreProblems' | translate }}</div>
  <ul>
    @for (error of errorMessages; track error) {
      <li [innerHtml]="error"></li>
    }
  </ul>
  <div>{{ 'workflowErrorsModal.pleaseCorrectErrors' | translate }}</div>

  <div class="buttons-group">
    <button
      class="btn btn-primary decline-btn"
      (click)="activeModal.dismiss()"
    >
      {{ 'common.buttons.close' | translate }}
    </button>
  </div>
</div>
