<div>
  <div>{{ 'decisionModal.strings.if' | translate }}</div>
  <div>
    <app-select
      [(ngModel)]="selectedField"
      [optionGroups]="fieldOptions"
      returnFullOption="true"
      placeholder="decisionModal.placeholders.selectField"
      [class.has-error]="showErrors && !selectedField"
    >
    </app-select>
    @if (showErrors && !selectedField) {
      <div class="custom-control-error">
        {{ 'criteriaSelector.fieldRequired' | translate }}
      </div>
    }
  </div>
</div>
@if (selectedField) {
  <div>
    <div>{{ 'decisionModal.strings.state' | translate }}</div>
    <div>
      <app-select
        [disabled]="criteria?.fieldId !== 0 && !criteria?.fieldId"
        [(ngModel)]="selectedState"
        [options]="stateOptions"
        placeholder="decisionModal.placeholders.selectState"
        [class.has-error]="showErrors && !selectedState"
      >
      </app-select>
      @if (showErrors && !selectedState) {
        <div class="custom-control-error">
          {{ 'criteriaSelector.stateRequired' | translate }}
        </div>
      }
    </div>
  </div>
}
@if (showTarget) {
  <div>
    <div>{{ 'decisionModal.strings.target' | translate }}</div>
    <div>
      <app-select
        [(ngModel)]="selectedTarget"
        [options]="targetOptions"
        placeholder="decisionModal.placeholders.selectTarget"
        [class.has-error]="showErrors && !selectedTarget"
      >
      </app-select>
      @if (showErrors && !selectedTarget) {
        <div class="custom-control-error">
          {{ 'criteriaSelector.targetRequired' | translate }}
        </div>
      }
    </div>
  </div>
}
@if (showTarget && showTargetField) {
  <div>
    <div>{{ 'decisionModal.strings.field' | translate }}</div>
    <div>
      <app-select
        [(ngModel)]="selectedTargetField"
        [optionGroups]="targetFieldOptions"
        returnFullOption="true"
        placeholder="decisionModal.placeholders.selectField"
        [class.has-error]="showErrors && !selectedTargetField"
      >
      </app-select>
      @if (showErrors && !selectedTargetField) {
        <div class="custom-control-error">
          {{ 'criteriaSelector.targetRequired' | translate }}
        </div>
      }
    </div>
  </div>
}
@if (showValue) {
  <div>
    <div>{{ 'decisionModal.strings.value' | translate }}</div>
    <div>
      @if (
        selectedField.controlType !== 'SingleSelectList' &&
        selectedField.controlType !== 'MultiSelectList' &&
        selectedField.controlType !== 'Date' &&
        selectedField.controlType !== 'Number'
      ) {
        <input
          placeholder="{{ 'decisionModal.placeholders.enterValue' | translate }}"
          [(ngModel)]="selectedValue"
          [class.has-error]="showErrors && (!selectedValue || selectedValue.length === 0)"
        />
      }
      @if (selectedField.controlType === 'Number') {
        <input
          mask="0*.0*"
          [dropSpecialCharacters]="false"
          placeholder="{{ 'decisionModal.placeholders.enterValue' | translate }}"
          [(ngModel)]="selectedValue"
          [class.has-error]="showErrors && (!selectedValue || selectedValue.length === 0)"
        />
      }
      @if (selectedField.controlType === 'SingleSelectList' || selectedField.controlType === 'MultiSelectList') {
        <app-select
          [(ngModel)]="selectedValue"
          [options]="valueOptions"
          placeholder="decisionModal.placeholders.selectValue"
          [class.has-error]="showErrors && !selectedValue"
        ></app-select>
      }
      @if (selectedField.controlType === 'Date') {
        <app-date-picker
          [class.has-error]="showErrors && !selectedValue"
          placeholder="decisionModal.placeholders.selectValue"
          [(ngModel)]="selectedValue"
        >
        </app-date-picker>
      }
      @if (showErrors && (!selectedValue || selectedValue.length === 0)) {
        <div class="custom-control-error">
          {{ 'criteriaSelector.valueRequired' | translate }}
        </div>
      }
    </div>
  </div>
}
