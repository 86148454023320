<div class="modal-header">
  <button
    [appFocusable]
    type="button"
    class="close-btn"
    aria-label="Close"
    (click)="activeModal.dismiss()"
  >
    <img
      appAssetSrc="assets/svg/common/cross.svg"
      alt="{{ 'common.buttons.close' | translate }}"
    />
  </button>
</div>

<div class="modal-body">
  <form
    [formGroup]="form"
    class="workflow-step-modal_form"
    #ngForm="ngForm"
    (ngSubmit)="save()"
  >
    <div class="workflow-step-modal_header">
      <div>
        <span>{{ 'common.labels.step' | translate }}: </span>
        <span>{{ 'sendEmailNotificationModal.title' | translate }}</span>
      </div>
      <div>
        <div>{{ 'sendEmailNotificationModal.titleMsg' | translate }}</div>
        <button
          type="submit"
          class="btn btn-primary submit-btn"
        >
          {{ 'common.buttons.save&close' | translate }}
        </button>
      </div>
    </div>

    <div class="workflow-step-modal_scroll-area">
      <scrollable-area [maxHeight]="maxScrollAreaHeight">
        <div
          class="form-group"
          [ngClass]="{
            'has-error': ngForm.submitted && form.get('name').invalid,
          }"
        >
          <label
            class="required"
            for="actionName"
            >{{ 'workflows.controls.actionName.label' | translate }}</label
          >
          <input
            formControlName="name"
            id="actionName"
            placeholder="{{ 'workflows.controls.actionName.placeholder' | translate }}"
          />
          <form-error-messages
            [showErrors]="ngForm.submitted"
            [control]="form.get('name')"
          >
          </form-error-messages>
        </div>

        <div
          class="form-group"
          [ngClass]="{
            'has-error': ngForm.submitted && form.get('description').invalid,
          }"
        >
          <label
            class="required"
            for="description"
            >{{ 'workflows.controls.actionDescription.label' | translate }}</label
          >
          <textarea
            formControlName="description"
            id="description"
            placeholder="{{ 'workflows.controls.actionDescription.placeholder' | translate }}"
          >
          </textarea>
          <form-error-messages
            [showErrors]="ngForm.submitted"
            [control]="form.get('description')"
          >
          </form-error-messages>
        </div>

        <div
          class="form-group send-email-modal_subject"
          [ngClass]="{
            'has-error': ngForm.submitted && form.get('emailSubject').invalid,
          }"
        >
          <label
            class="required"
            for="emailBody"
            >{{ 'sendEmailNotificationModal.controls.emailSubject.label' | translate }}</label
          >
          <input
            formControlName="emailSubject"
            id="emailSubject"
            placeholder="{{ 'sendEmailNotificationModal.controls.emailSubject.placeholder' | translate }}"
          />
          <form-error-messages
            [showErrors]="ngForm.submitted"
            [control]="form.get('emailSubject')"
          >
          </form-error-messages>
        </div>

        <div
          class="form-group"
          [ngClass]="{
            'has-error': ngForm.submitted && form.get('emailBody').invalid,
          }"
        >
          <label
            class="required"
            for="emailBody"
            >{{ 'sendEmailNotificationModal.controls.emailBody.label' | translate }}</label
          >
          <rich-text-editor
            formControlName="emailBody"
            id="emailBody"
            customDropdown="true"
            [workflowType]="workflowType"
          >
          </rich-text-editor>
          <form-error-messages
            [showErrors]="ngForm.submitted"
            [control]="form.get('emailBody')"
          >
          </form-error-messages>
        </div>

        <div class="send-email-modal_data-export">
          <h3>{{ 'workflows.dataDelivery' | translate }}</h3>
          <div class="send-email-modal_data-export-switch">
            <app-toggle formControlName="shouldTaskDataBeIncluded"></app-toggle>
            <label>{{ 'workflows.controls.includeTaskData.label' | translate }}</label>
          </div>

          @if (form.get('shouldTaskDataBeIncluded').value) {
            <div
              class="form-group send-email-modal_file-handling-type"
              [ngClass]="{
                'has-error': ngForm.submitted && form.get('fileHandlingType').invalid,
              }"
            >
              <label for="fileHandlingType">{{ 'workflows.controls.fileHandling.label' | translate }}</label>
              <app-select
                formControlName="fileHandlingType"
                id="fileHandlingType"
                placeholder="workflows.controls.fileHandling.placeholder"
                [options]="fileHandlingTypes"
              >
              </app-select>
              <form-error-messages
                [showErrors]="ngForm.submitted"
                [control]="form.get('fileHandlingType')"
              >
              </form-error-messages>
            </div>
          }
        </div>

        <div class="form-group workflow-step-modal_recipients">
          <label
            class="required"
            for="text"
            >{{ 'workflows.controls.recipientsSelector.label' | translate }}</label
          >

          <div
            class="form-group send-email-modal_additional-emails"
            [ngClass]="{
              'has-error': ngForm.submitted && form.get('additionalRecipientEmails').invalid,
            }"
          >
            <label for="text">
              {{ 'sendEmailNotificationModal.controls.additionalEmails.label' | translate }}
            </label>
            <multiple-string-input formControlName="additionalRecipientEmails"> </multiple-string-input>
            <form-error-messages
              [showErrors]="ngForm.submitted"
              [control]="form.get('additionalRecipientEmails')"
              [customErrors]="{
                invalid: 'sendEmailNotificationModal.controls.additionalEmails.invalidEmailsError',
              }"
            >
            </form-error-messages>
          </div>

          <recipient-selector [(selectedUsers)]="selectedUsers"></recipient-selector>
        </div>
      </scrollable-area>
    </div>
  </form>
</div>
