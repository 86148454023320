<div class="workflow-library_folders folders">
  <h1>{{ 'workflowLibrary.title' | translate }}</h1>

  <div class="folders-title">
    {{ 'workflowLibrary.companyTemplates' | translate }}
  </div>
  <scrollable-area>
    <div class="folders-container">
      @for (folder of folders; track folder) {
        <div
          [appFocusable]
          class="folder"
          [class.selected]="selectedFolder?.id === folder.id"
          (click)="selectFolder(folder)"
        >
          <img
            [appAssetSrc]="
              selectedFolder?.id === folder.id ? 'assets/svg/files/folder-selected.svg' : 'assets/svg/files/folder.svg'
            "
          />
          <div class="folder-name">{{ folder.name }}</div>
          @if (publishMode) {
            <div
              ngbDropdown
              placement="bottom-right"
              class="dropdown folder-actions"
            >
              <span
                [appFocusable]
                ngbDropdownToggle
                class="dropdown-toggle"
              ></span>
              <div
                ngbDropdownMenu
                aria-labelledby="dropdown"
              >
                <button
                  [appFocusable]
                  class="dropdown-item"
                  (click)="renameFolder(folder)"
                >
                  {{ 'common.buttons.rename' | translate }}
                </button>
                <button
                  [appFocusable]
                  class="dropdown-item"
                  (click)="deleteFolder(folder)"
                >
                  {{ 'common.buttons.delete' | translate }}
                </button>
              </div>
            </div>
          }
        </div>
      }
    </div>
  </scrollable-area>
  @if (publishMode) {
    <div
      [appFocusable]
      class="folders_add-folder-btn"
      (click)="createFolder()"
    >
      + {{ 'filesPage.buttons.newFolder' | translate }}
    </div>
  }
</div>

<div class="workflow-library_table">
  <button
    [appFocusable]
    type="button"
    class="workflow-library_close close-btn"
    aria-label="Close"
    (click)="activeModal.dismiss()"
  >
    <img
      appAssetSrc="assets/svg/common/cross.svg"
      alt="{{ 'common.buttons.close' | translate }}"
    />
  </button>

  <div class="workflow-library_table-title">
    @if (!selectedFolder) {
      <div>
        {{ 'workflowLibrary.companyTemplates' | translate }}
      </div>
    }
    @if (selectedFolder) {
      <div>{{ selectedFolder.name }}</div>
    }
  </div>

  @if (selectedFolder && tableConfig) {
    <app-table
      [config]="tableConfig"
      [(data)]="data"
      [template]="layout === TableLayout.Table ? tableTemplate : gridTemplate"
      [placeholderTemplate]="placeholderTemplate"
    >
      <ng-template #placeholderTemplate>
        <div class="table_placeholder-message">
          {{ 'workflowLibrary.noWorkflows' | translate }}
        </div>
      </ng-template>
      <ng-template
        #tableTemplate
        let-workflow
      >
        <div
          class="workflow-library_table-cell"
          [class.disabled]="isWorkflowDisabled(workflow)"
          [appFocusable]
          (click)="onWorkflowClick(workflow)"
        >
          <div class="workflow-library_image-container">
            @if (!workflow.thumbnailImage) {
              <img appAssetSrc="assets/svg/file-icons/generic.svg" />
            }
            @if (workflow.thumbnailImage) {
              <img [src]="workflow.thumbnailImage" />
            }
          </div>
          <div class="workflow-library_file-name">
            {{ workflow.name }} {{ 'workflowsPage.workflowVersion' | translate: { version: workflow.version || 0 } }}
          </div>
        </div>
        <div
          class="workflow-library_table-cell"
          [class.disabled]="isWorkflowDisabled(workflow)"
          (click)="onWorkflowClick(workflow)"
        >
          {{ workflow.type }}
        </div>
        <div></div>
      </ng-template>
      <ng-template
        #gridTemplate
        let-workflow
      >
        @if (!isWorkflowDisabled(workflow)) {
          <div
            class="workflow-library_grid-cell"
            [appFocusable]
            (click)="onWorkflowClick(workflow)"
          >
            <div class="workflow-library_image-container">
              @if (!workflow.thumbnailImage) {
                <img appAssetSrc="assets/svg/file-icons/generic-large.svg" />
              }
              @if (workflow.thumbnailImage) {
                <img [src]="workflow.thumbnailImage" />
              }
              <div class="workflow-library_workflow-type">
                {{ workflow.type }}
              </div>
            </div>
            @if (workflow.name?.length < 25) {
              <div class="workflow-library_file-name">
                {{ workflow.name }}
                <span class="workflow-library_file-name-version">
                  {{ 'workflowsPage.workflowVersion' | translate: { version: workflow.version || 0 } }}
                </span>
              </div>
            }
            @if (workflow.name?.length >= 25) {
              <app-tooltip
                theme="black"
                [contentTemplate]="nameTooltip"
              >
                <div class="workflow-library_file-name">{{ workflow.name }}</div>
              </app-tooltip>
            }
            <ng-template #nameTooltip>
              <div class="workflow-library_tooltip-content">
                {{ workflow.name }}
              </div>
            </ng-template>
          </div>
        }
        @if (isWorkflowDisabled(workflow)) {
          <app-tooltip
            theme="black"
            [contentTemplate]="workflow.type === 'Company' ? companyWfTooltip : productWfTooltip"
          >
            <div class="workflow-library_grid-cell disabled">
              <div class="workflow-library_image-container">
                @if (!workflow.thumbnailImage) {
                  <img appAssetSrc="assets/svg/file-icons/generic-large.svg" />
                }
                @if (workflow.thumbnailImage) {
                  <img [src]="workflow.thumbnailImage" />
                }
                <div class="workflow-library_workflow-type">
                  {{ workflow.type }}
                </div>
              </div>
              <div class="workflow-library_file-name">{{ workflow.name }}</div>
            </div>
          </app-tooltip>
        }
        <ng-template #companyWfTooltip>
          <div class="workflow-library_tooltip-content">
            {{ 'workflowLibrary.companyWfTooltip' | translate }}
          </div>
        </ng-template>
        <ng-template #productWfTooltip>
          <div class="workflow-library_tooltip-content">
            {{ 'workflowLibrary.productWfTooltip' | translate }}
          </div>
        </ng-template>
      </ng-template>
    </app-table>
  }
</div>
