<app-table
  #table
  [(data)]="data"
  [config]="tableConfig"
  [placeholderTemplate]="placeholderTemplate"
  [template]="rowTemplate"
>
  <ng-template
    #rowTemplate
    let-user
  >
    <div>
      @switch (mode) {
        @case ('single') {
          <app-radio
            name="recipient-selector-item"
            (change)="changeSelection(user)"
            [checked]="isUserSelected(user)"
          />
        }
        @case ('multiple') {
          <app-checkbox
            (change)="changeSelection(user)"
            [checked]="isUserSelected(user)"
          />
        }
      }
    </div>
    <div class="recipient-selector_name">
      <user-logo [user]="user"></user-logo>
      <span>{{ user.firstName }} {{ user.lastName }}</span>
    </div>
    <div>
      {{ user.email }}
    </div>
    <div></div>
  </ng-template>
  <ng-template #placeholderTemplate>
    <div class="table_placeholder-message">
      <div>{{ 'common.strings.noMatchesFound' | translate }}</div>
    </div>
  </ng-template>
</app-table>
