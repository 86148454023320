import { Component, Input, ViewChild, ViewEncapsulation, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { TableComponent, TableComponent as TableComponent_1 } from '@core/components/table/table.component';
import { type TableConfig } from '@core/models/table';
import { type User } from '@core/models/user';

import { CheckboxComponent } from '../../../../stories/forms/checkbox/checkbox.component';
import { RadioComponent } from '../../../../stories/forms/radio/radio.component';
import { UserLogoComponent } from '../../../core/components/user-logo/user-logo.component';

@Component({
  selector: 'recipient-selector',
  templateUrl: './recipient-selector.component.html',
  styleUrls: ['./recipient-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [TableComponent_1, RadioComponent, CheckboxComponent, UserLogoComponent, TranslateModule],
})
export class RecipientSelectorComponent {
  @Input() selectedUsers: number[] = [];
  @Input() mode: 'single' | 'multiple' = 'multiple';

  selectedUsersChange = output<number[]>();

  @ViewChild('table') table: TableComponent;

  public data: User[] = [];

  public isLoading = false;
  public tableConfig: TableConfig = {
    loadUrl: 'api/colleagues/search',
    loadLimit: 10,
    defaultSort: 'fullname',
    defaultSortDirection: 'asc',
    filters: [
      {
        name: 'selectionFilter',
        defaultValue: 0,
        toStringFunc: (val) => {
          if (!+val || this.selectedUsers.length === 0) {
            return ``;
          }

          const param = +val === 1 ? 'UserIdsToInclude' : 'UserIdsToExclude';
          return this.selectedUsers.map((id) => `${param}=${id}`).reduce((p, c) => `${p}&${c}`);
        },
        options: [
          {
            label: 'recipientSelector.selectionFilter.all',
            value: 0,
          },
          {
            label: 'recipientSelector.selectionFilter.selected',
            value: 1,
          },
          {
            label: 'recipientSelector.selectionFilter.unselected',
            value: 2,
          },
        ],
      },
    ],
    columns: [
      {
        name: 'checkbox',
        sortable: false,
        minWidth: '40px',
        maxWidth: '40px',
      },
      {
        name: 'fullname',
        label: 'recipientSelector.columns.recipient',
        sortable: true,
        minWidth: '200px',
        maxWidth: '1fr',
      },
      {
        name: 'email',
        label: 'common.labels.email',
        sortable: true,
        minWidth: '200px',
        maxWidth: '1fr',
      },
      {
        name: 'teams',
        label: 'recipientSelector.columns.teams',
        sortable: true,
        minWidth: '200px',
        maxWidth: '1fr',
      },
    ],
  };

  public isUserSelected(user: User): boolean {
    return this.selectedUsers.findIndex((id) => user.id === id) !== -1;
  }

  public changeSelection(user: User): void {
    let value: number[];
    if (this.isUserSelected(user)) {
      switch (this.mode) {
        case 'single':
          value = [];
          break;
        case 'multiple':
          value = this.selectedUsers.filter((id) => id !== user.id);
          break;
      }

      if (+this.table.filterValues.selectionFilter === 1) {
        this.data = this.data.filter((us) => us.id !== user.id);
      }
    } else {
      switch (this.mode) {
        case 'single':
          value = [user.id];
          break;
        case 'multiple':
          value = [...this.selectedUsers, user.id];
          break;
      }

      if (+this.table.filterValues.selectionFilter === 2) {
        this.data = this.data.filter((us) => us.id !== user.id);
      }
    }

    this.selectedUsersChange.emit(value);
  }
}
