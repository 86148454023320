<div class="modal-header">
  <button
    (click)="activeModal.dismiss()"
    [appFocusable]
    aria-label="Close"
    class="close-btn"
    type="button"
  >
    <img
      alt="{{ 'common.buttons.close' | translate }}"
      appAssetSrc="assets/svg/common/cross.svg"
    />
  </button>
</div>

<div class="modal-body">
  <form
    #ngForm="ngForm"
    (ngSubmit)="save()"
    [formGroup]="form"
    class="workflow-step-modal_form"
  >
    <div class="workflow-step-modal_header">
      <div>
        <span>{{ 'common.labels.step' | translate }}: </span>
        <span>{{ 'decisionModal.title' | translate }}</span>
      </div>
      <div>
        <div>{{ 'decisionModal.titleMsg' | translate }}</div>
        <button
          class="btn btn-primary submit-btn"
          type="submit"
        >
          {{ 'common.buttons.save&close' | translate }}
        </button>
      </div>
    </div>

    <div
      [ngStyle]="{ height: maxScrollAreaHeight }"
      class="workflow-step-modal_scroll-area"
    >
      @if (!fields) {
        <loader></loader>
      }
      @if (fields) {
        <scrollable-area #scrollArea>
          <div
            [ngClass]="{
              'has-error': ngForm.submitted && form.get('name').invalid,
            }"
            class="form-group"
          >
            <label
              class="required"
              for="stepName"
              >{{ 'workflows.controls.stepName.label' | translate }}</label
            >
            <input
              formControlName="name"
              id="stepName"
              placeholder="{{ 'workflows.controls.stepName.placeholder' | translate }}"
            />
            <form-error-messages
              [control]="form.get('name')"
              [showErrors]="ngForm.submitted"
            >
            </form-error-messages>
          </div>
          <div
            [ngClass]="{
              'has-error': ngForm.submitted && form.get('description').invalid,
            }"
            class="form-group"
          >
            <label
              class="required"
              for="description"
              >{{ 'workflows.controls.stepDescription.label' | translate }}</label
            >
            <textarea
              formControlName="description"
              id="description"
              placeholder="{{ 'workflows.controls.stepDescription.placeholder' | translate }}"
            >
            </textarea>
            <form-error-messages
              [control]="form.get('description')"
              [showErrors]="ngForm.submitted"
            >
            </form-error-messages>
          </div>
          <div class="decision-modal_criteria-selector">
            <h3>
              {{ 'decisionModal.defineCriteria' | translate }}
            </h3>
            @for (criteria of criterias; track criteria) {
              <div class="decision-modal_criteria">
                <div class="decision-modal_criteria-body">
                  <criteria-selector
                    (valueChange)="onCriteriaChange($event)"
                    [criteriaStates]="$any(criteriaStates)"
                    [fields]="fields"
                    [showErrors]="ngForm.submitted"
                    [value]="criteria"
                  >
                  </criteria-selector>
                </div>
                <div class="decision-modal_criteria-buttons">
                  <div
                    (click)="addCriteria()"
                    [appFocusable]
                    class="decision-modal_new-criteria-btn"
                  >
                    <img appAssetSrc="assets/svg/common/cross-white.svg" />
                  </div>
                  @if (criterias.length > 1) {
                    <div
                      (click)="removeCriteria(criteria)"
                      [appFocusable]
                      class="decision-modal_delete-criteria-btn"
                    >
                      <img appAssetSrc="assets/svg/common/cross-blue.svg" />
                    </div>
                  }
                </div>
              </div>
            }
          </div>
          @if (criterias && criterias.length > 1) {
            <div class="decision-modal_operator-selector">
              <span>{{ 'decisionModal.strings.when' | translate }}</span>
              <app-select
                [options]="operatorOptions"
                formControlName="operator"
              ></app-select>
              <span>{{ 'decisionModal.ofTheseRules' | translate }}</span>
            </div>
          }
          <div class="decision-modal_action-selector">
            <h3>
              {{ 'decisionModal.defineAction' | translate }}
            </h3>
            <div class="decision-modal_action">
              <div>{{ 'decisionModal.strings.do' | translate }}</div>
              <div
                [ngClass]="{
                  'has-error': ngForm.submitted && form.get('branch').invalid,
                }"
                class="form-group"
              >
                <app-select
                  [class.has-error]="ngForm.submitted && form.get('branch').invalid"
                  [options]="branchOptions"
                  formControlName="branch"
                  placeholder="decisionModal.placeholders.selectAction"
                >
                </app-select>
                @if (ngForm.submitted && form.get('branch').invalid) {
                  <div class="custom-control-error">
                    {{ 'decisionModal.actionRequired' | translate }}
                  </div>
                }
              </div>
            </div>
          </div>
        </scrollable-area>
      }
    </div>
  </form>
</div>
