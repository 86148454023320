import { NgClass } from '@angular/common';
import { Component, inject, Input, type AfterViewInit, type OnInit } from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { ScrollableAreaComponent } from '@clover/core/components/scrollable-area/scrollable-area.component';
import { AssetSrcDirective } from '@clover/core/directives/asset-src.directive';
import { FocusableDirective } from '@clover/core/directives/focusable.directive';

import { FormErrorMessagesComponent } from '../../../core/components/form-error-messages/form-error-messages.component';
import { RichTextEditorComponent } from '../../../core/components/rich-text-editor/rich-text-editor.component';
import { RecipientSelectorComponent } from '../../components/recipient-selector/recipient-selector.component';

@Component({
  selector: 'cc-approval-modal',
  standalone: true,
  imports: [
    FocusableDirective,
    AssetSrcDirective,
    FormsModule,
    ReactiveFormsModule,
    ScrollableAreaComponent,
    NgClass,
    FormErrorMessagesComponent,
    RichTextEditorComponent,
    TranslateModule,
    RecipientSelectorComponent,
  ],
  templateUrl: './approval-modal.component.html',
  styleUrl: './approval-modal.component.scss',
})
export class ApprovalModalComponent implements OnInit, AfterViewInit {
  @Input() data: any;
  @Input() workflowType: string;

  form: UntypedFormGroup;
  maxScrollAreaHeight: number;

  protected readonly activeModal = inject(NgbActiveModal);

  get selectedApprovers(): number[] {
    const approvers = this.form.get('approvers').value;
    return approvers || [];
  }

  ngOnInit(): void {
    const data = this.data ? this.data : {};
    if (Object.keys(data).length > 0) {
      const regex = /\[\[\s*(.*?)\s*\]\]/g;
      data.approverInstructions.html = data.approverInstructions.html.replace(
        regex,
        '<span class="workflow-token">$1</span>',
      );
    }
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(data['name'], [Validators.required]),
      description: new UntypedFormControl(data['description'], [Validators.required]),
      approverInstructions: new UntypedFormControl(data['approverInstructions'], [Validators.required]),
      approvers: new FormControl<number[]>(data['audience']?.['contactIds'] || [], [Validators.required]),
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.maxScrollAreaHeight = this.getMaxScrollAreaHeight();
    }, 0);
  }

  save(): void {
    if (this.form.invalid) {
      setTimeout(() => {
        const firstError = document.querySelector('.workflow-step-modal_scroll-area .has-error');
        firstError.scrollIntoView();
      }, 0);

      return;
    }

    this.activeModal.close({
      name: this.form.get('name').value,
      description: this.form.get('description').value,
      approverInstructions: this.form.get('approverInstructions').value,
      audience: {
        contactIds: this.form.get('approvers').value,
      },
    });
  }

  handleApproversChange(approvers: number[]): void {
    const approversControl = this.form.get('approvers');
    approversControl.setValue(approvers || []);
  }

  private getMaxScrollAreaHeight(): number {
    const headerHeight = document.querySelector('.workflow-step-modal_header').clientHeight;
    const modalHeaderHeight = document.querySelector('.modal-header').clientHeight;
    return document.body.clientHeight - headerHeight - modalHeaderHeight - 64;
  }
}
