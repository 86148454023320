<div class="modal-header">
  <button
    [appFocusable]
    type="button"
    class="close-btn"
    aria-label="Close"
    (click)="activeModal.dismiss()"
  >
    <img
      appAssetSrc="assets/svg/common/cross.svg"
      alt="{{ 'common.buttons.close' | translate }}"
    />
  </button>
</div>

<div class="modal-body">
  <form
    [formGroup]="form"
    class="workflow-step-modal_form"
    #ngForm="ngForm"
    (ngSubmit)="save()"
  >
    <div class="workflow-step-modal_header">
      <div>
        <span>{{ 'common.labels.step' | translate }}: </span>
        <span>{{ 'docusignModal.title' | translate }}</span>
      </div>
      <div>
        <div>{{ 'docusignModal.titleMsg' | translate }}</div>
        <button
          type="submit"
          class="btn btn-primary submit-btn"
        >
          {{ 'common.buttons.save&close' | translate }}
        </button>
      </div>
    </div>

    <div class="workflow-step-modal_scroll-area">
      <scrollable-area [maxHeight]="maxScrollAreaHeight">
        <div
          class="form-group"
          [ngClass]="{
            'has-error': ngForm.submitted && form.get('name').invalid,
          }"
        >
          <label
            class="required"
            for="stepName"
            >{{ 'workflows.controls.stepName.label' | translate }}</label
          >
          <input
            formControlName="name"
            id="stepName"
            placeholder="{{ 'workflows.controls.stepName.placeholder' | translate }}"
          />
          <form-error-messages
            [showErrors]="ngForm.submitted"
            [control]="form.get('name')"
          >
          </form-error-messages>
        </div>

        <div
          class="form-group"
          [ngClass]="{
            'has-error': ngForm.submitted && form.get('description').invalid,
          }"
        >
          <label
            class="required"
            for="description"
            >{{ 'workflows.controls.stepDescription.label' | translate }}</label
          >
          <textarea
            formControlName="description"
            id="description"
            placeholder="{{ 'workflows.controls.stepDescription.placeholder' | translate }}"
          >
          </textarea>
          <form-error-messages
            [showErrors]="ngForm.submitted"
            [control]="form.get('description')"
          >
          </form-error-messages>
        </div>

        <div
          class="form-group"
          [ngClass]="{
            'has-error': ngForm.submitted && form.get('text').invalid,
          }"
        >
          <label
            class="required"
            for="text"
            >{{ 'instructionalTextModal.controls.text.label' | translate }}</label
          >
          <rich-text-editor
            formControlName="text"
            id="text"
            customDropdown="true"
            [workflowType]="workflowType"
          >
          </rich-text-editor>
          <form-error-messages
            [showErrors]="ngForm.submitted"
            [control]="form.get('text')"
          >
          </form-error-messages>
        </div>

        <div class="form-group">
          <label
            class="required"
            for="checkboxLabel"
            >{{ 'docusignModal.controls.selectTemplates.label' | translate }}</label
          >
          <p>
            {{ 'docusignModal.controls.selectTemplates.description' | translate }}
          </p>
          <div class="docusign-step_selected-templates">
            @for (template of selectedTemplates; track template) {
              <div
                [ngClass]="{
                  'has-error': ngForm.submitted && templatesControl.invalid && !template.fileCategory,
                }"
              >
                <div>{{ template.name }}</div>
                <app-select
                  [(ngModel)]="template.fileCategory"
                  [ngModelOptions]="{ standalone: true }"
                  placeholder="uploadFileModal.controls.fileCategory.placeholder"
                  [options]="fileCategories"
                  (valueChanged)="checkCategoriesSelection()"
                >
                </app-select>
                <div
                  [appFocusable]
                  class="docusign-step_remove-template-btn"
                  (click)="removeTemplate(template)"
                >
                  <img appAssetSrc="assets/svg/common/cross-black.svg" />
                </div>
              </div>
            }
          </div>
          <form-error-messages
            [showErrors]="ngForm.submitted"
            [control]="templatesControl"
            [customErrors]="templateErrors"
          >
          </form-error-messages>
          <div class="docusign-step_buttons">
            @if (!templatesControl.value?.length) {
              <button
                type="button"
                class="btn btn-primary submit-btn"
                (click)="selectTemplates()"
              >
                {{ 'docusignModal.controls.selectTemplates.buttonText' | translate }}
              </button>
            }
          </div>
        </div>
      </scrollable-area>
    </div>
  </form>
</div>
