<div class="modal-body">
  <button
    [appFocusable]
    type="button"
    class="close-btn"
    aria-label="Close"
    (click)="activeModal.dismiss()"
  >
    <img
      appAssetSrc="assets/svg/common/cross.svg"
      alt="{{ 'common.buttons.close' | translate }}"
    />
  </button>

  <h2>
    <span>{{ 'selectTemplatesModal.title' | translate }}</span>
  </h2>

  @if (tableConfig) {
    <app-table
      #table
      [config]="tableConfig"
      [(data)]="templates"
      [template]="tableTemplate"
    >
      <ng-template
        #tableTemplate
        let-file
      >
        <div
          class="template-selector_file-name"
          [class.highlighted]="isFileSelected(file)"
          (click)="handleFileClick(file)"
        >
          <div class="template-selector_image-container">
            <img appAssetSrc="assets/svg/file-icons/generic.svg" />
          </div>
          <div>{{ file.name }}</div>
        </div>
      </ng-template>
    </app-table>
  }
</div>
