import { type FieldGroupType } from './workflow-field';

export interface DecisionCriteria {
  id?: number;
  fieldId?: string;
  fieldGroup?: FieldGroupType;
  state?: string;
  target?: string;
  value?: string;
  targetFieldGroup?: FieldGroupType;
  targetFieldId?: string;
}

export interface DecisionCriteriaState {
  key: any;
  title: string;
  isValueRequired?: boolean;
  targetOptions?: CriteriaTarget[];
}

export interface CriteriaTarget {
  key: any;
  title: string;
}

export enum DecisionCriteriaOperator {
  Any = '0',
  All = '1',
}

export enum DecisionBranch {
  A = '0',
  B = '1',
}
