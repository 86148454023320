import { type CustomFieldType } from '@clover/custom-fields/models/custom-field';
import { type CustomFormFieldType } from '@clover/custom-forms/models/enums';

export interface WorkflowField {
  fieldId: string;
  fieldGroup?: FieldGroupType;
  type: CustomFieldType;
  controlType: CustomFormFieldType;
  label: string;
  options?: Array<{ key: string; title: string; sortOrder?: number }>;
}

export enum FieldGroupType {
  Public = 'Public',
  Private = 'Private',
  Confidential = 'Confidential',
  Proprietary = 'Proprietary',
  Logic = 'Logic',
  BusinessObject = 'BusinessObject',
}
