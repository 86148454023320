<div class="modal-body">
  <h1>{{ 'shareWorkflowModal.title' | translate }}</h1>
  <p>{{ 'shareWorkflowModal.titleMsg' | translate }}</p>
  <div class="share-workflow-modal_content">
    @if (isLoaded) {
      <scrollable-area
        (scrollDown)="loadTeams()"
        [scrollActivationPercent]="30"
      >
        <div
          (click)="selectedType = assignmentTypes.all"
          [appFocusable]
          class="d-flex share-workflow-modal_assignment-type"
        >
          <app-radio [checked]="selectedType === assignmentTypes.all"></app-radio>
          <span class="radio-label">
            {{ 'shareWorkflowModal.allAssigners' | translate }}
          </span>
        </div>
        <div
          (click)="selectedType = assignmentTypes.teams"
          [appFocusable]
          class="d-flex share-workflow-modal_assignment-type"
        >
          <app-radio [checked]="selectedType === assignmentTypes.teams"> </app-radio>
          <span class="radio-label">
            {{ 'shareWorkflowModal.teams' | translate }}
          </span>
        </div>
        @for (team of teams; track team) {
          <app-checkbox
            (change)="toggleSelection(team)"
            [checked]="selectedTeamIds.includes(team.id)"
            [disabled]="selectedType === assignmentTypes.all"
            [label]="team.name"
          >
          </app-checkbox>
        }
      </scrollable-area>
    }
    @if (!isLoaded) {
      <loader></loader>
    }
  </div>
  <div class="share-workflow-modal_buttons">
    <button
      (click)="activeModal.dismiss()"
      class="btn btn-primary decline-btn"
    >
      {{ (isPublishing ? 'shareWorkflowModal.doLater' : 'common.buttons.cancel') | translate }}
    </button>
    <button
      (click)="save()"
      [appBtnLoading]="isSaving"
      [disabled]="selectedType === assignmentTypes.teams && !selectedTeamIds.length"
      class="btn btn-primary submit-btn"
    >
      {{ 'common.buttons.save' | translate }}
    </button>
  </div>
</div>
