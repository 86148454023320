import { Component, Input, type SimpleChanges, ViewEncapsulation, output, OnChanges, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective } from 'ngx-mask';

import { DatePickerComponent } from '@core/components/date-picker/date-picker.component';
import { SelectComponent, type SelectGroup, type SelectOption } from '@core/components/select/select.component';

import { DecisionCriteria, type DecisionCriteriaState } from '../../models/decision-criteria';
import { FieldGroupType, type WorkflowField } from '../../models/workflow-field';

@Component({
  selector: 'criteria-selector',
  templateUrl: './criteria-selector.component.html',
  styleUrls: ['./criteria-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [SelectComponent, FormsModule, NgxMaskDirective, DatePickerComponent, TranslateModule],
})
export class CriteriaSelectorComponent implements OnChanges, OnInit {
  @Input() disabled = false;
  @Input() fields: WorkflowField[] = [];
  @Input() showErrors = false;
  @Input() value: DecisionCriteria;
  @Input() criteriaStates: DecisionCriteriaState[] = [];

  valueChange = output<DecisionCriteria>();

  public fieldOptions: SelectGroup[] = [];
  public stateOptions: DecisionCriteriaState[] = [];
  public targetOptions: SelectOption[] = [];
  public targetFieldOptions: SelectGroup[] = [];
  public valueOptions: SelectOption[] = [];

  public showValue = false;
  public showTarget = false;
  public showTargetField = false;
  private _selectedField: WorkflowField;

  public get selectedField(): WorkflowField {
    return this._selectedField;
  }

  public set selectedField(value: WorkflowField) {
    this._selectedField = value;
    const newCriteria = {
      ...this.criteria,
      fieldId: value.fieldId,
      fieldGroup: value.fieldGroup,
    };

    this.rebuildOptions(newCriteria);
    this.checkState(newCriteria);

    this.criteria = newCriteria;
  }

  private _selectedTargetField: WorkflowField;

  public get selectedTargetField(): WorkflowField {
    return this._selectedTargetField;
  }

  public set selectedTargetField(value: WorkflowField) {
    this._selectedTargetField = value;
    this.criteria = {
      ...this.criteria,
      targetFieldId: value.fieldId,
      targetFieldGroup: value.fieldGroup,
    };
  }

  private _criteria: DecisionCriteria;

  get criteria(): DecisionCriteria {
    return this._criteria;
  }

  private set criteria(criteria: DecisionCriteria) {
    this._criteria = criteria;
    this.valueChange.emit(criteria);
  }

  public get selectedState(): string {
    return this.criteria?.state;
  }

  public set selectedState(value: string) {
    const newCriteria = {
      ...this.criteria,
      state: value,
    };

    this.rebuildOptions(newCriteria);
    this.checkState(newCriteria);

    this.criteria = newCriteria;
  }

  public get selectedTarget(): string {
    return this.criteria?.target;
  }

  public set selectedTarget(value: string) {
    const newCriteria = {
      ...this.criteria,
      target: value,
    };

    this.rebuildOptions(newCriteria);
    this.checkState(newCriteria);
    this.criteria = newCriteria;
  }

  public get selectedValue(): string {
    return this.criteria?.value;
  }

  public set selectedValue(value: string) {
    this.criteria = {
      ...this.criteria,
      value: value,
      targetFieldId: null,
      targetFieldGroup: null,
    };
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.fields) {
      this.fieldOptions = this.getFieldGroups(this.fields);
      this.rebuildOptions(this.criteria);
    }
  }

  public ngOnInit(): void {
    if (!this.value) {
      return;
    }

    this._criteria = this.value;

    if (this.criteria.fieldId != undefined) {
      for (const group of this.fieldOptions) {
        const field = group.options.find(
          (opt) => opt.fieldId === this.criteria.fieldId && opt.fieldGroup === this.criteria.fieldGroup,
        );
        if (field) {
          this._selectedField = field as WorkflowField;
        }
      }
    }

    this.rebuildOptions(this.criteria);

    if (this.criteria.targetFieldId) {
      for (const group of this.targetFieldOptions) {
        const field = group.options.find(
          (opt) => opt.fieldId === this.criteria.targetFieldId && opt.fieldGroup === this.criteria.targetFieldGroup,
        );
        if (field) {
          this._selectedTargetField = field as WorkflowField;
        }
      }
    }

    if (this.criteria.fieldId != undefined && this.criteria.state != undefined) {
      this.checkState(this.criteria);
    }
  }

  public getStateOptions(): any[] {
    if (!this.selectedField) {
      return [];
    }

    return this.criteriaStates[this.selectedField.controlType] || [];
  }

  private getFieldGroups(fields: WorkflowField[]): SelectGroup[] {
    const publicFields = [];
    const privateFields = [];
    const configFields = [];
    const propertyFields = [];
    const logicFields = [];
    const businessObjectFields = [];

    for (const field of fields) {
      switch (field.fieldGroup) {
        case FieldGroupType.Logic: {
          logicFields.push(field);
          break;
        }
        case FieldGroupType.Public: {
          publicFields.push(field);
          break;
        }
        case FieldGroupType.Private: {
          privateFields.push(field);
          break;
        }
        case FieldGroupType.Confidential: {
          configFields.push(field);
          break;
        }
        case FieldGroupType.Proprietary: {
          propertyFields.push(field);
          break;
        }
        case FieldGroupType.BusinessObject: {
          businessObjectFields.push(field);
          break;
        }
        default: {
          break;
        }
      }
    }

    const groups = [];

    if (logicFields.length > 0) {
      groups.push({
        label: 'decisionModal.fieldGroups.logic',
        options: logicFields,
      });
    }

    if (publicFields.length > 0) {
      groups.push({
        label: 'decisionModal.fieldGroups.public',
        options: publicFields,
      });
    }

    if (privateFields.length > 0) {
      groups.push({
        label: 'decisionModal.fieldGroups.private',
        options: privateFields,
      });
    }

    if (configFields.length > 0) {
      groups.push({
        label: 'decisionModal.fieldGroups.confidential',
        options: configFields,
      });
    }

    if (propertyFields.length > 0) {
      groups.push({
        label: 'decisionModal.fieldGroups.custom',
        options: propertyFields,
      });
    }

    if (businessObjectFields.length > 0) {
      groups.push({
        label: 'decisionModal.fieldGroups.businessObject',
        options: businessObjectFields,
      });
    }

    return groups;
  }

  private rebuildOptions(criteria: DecisionCriteria): void {
    if (!criteria) {
      return;
    }

    if (criteria.fieldId != undefined) {
      this.stateOptions = this.getStateOptions();
      // checking if selected state doesnt exist in new list of states
      if (
        criteria.state != undefined &&
        this.stateOptions.find((state) => state.key === criteria.state) === undefined
      ) {
        criteria.target = null;
        criteria.state = null;
        criteria.value = null;
        criteria.targetFieldId = null;
        criteria.targetFieldGroup = null;
      }
    }

    // checking state and selected target
    if (criteria.state) {
      const selectedState = this.stateOptions.find((state) => state.key === criteria.state);
      this.targetOptions = selectedState?.targetOptions;

      if (this.targetOptions?.length) {
        if (criteria.target == undefined) {
          criteria.target = this.targetOptions[0].key;
        }
      } else {
        criteria.target = null;
      }
    }

    if (!criteria.target) {
      criteria.value = null;
      criteria.targetFieldId = null;
      return;
    }

    // checking target and generating target options
    if (this.selectedField && criteria.target !== null) {
      const targetFields = this.fields.filter(
        (field) =>
          this.areFieldTypesEqual(this.selectedField, field) &&
          !(field.fieldId === this.selectedField.fieldId && field.fieldGroup === this.selectedField.fieldGroup),
      );

      const selectedState = this.stateOptions.find((state) => state.key === criteria.state);
      this.targetOptions = selectedState?.targetOptions || [];
      this.targetFieldOptions = this.getFieldGroups(targetFields);

      if (criteria.target === 'Value' && this.selectedField.options?.length > 0) {
        this.valueOptions = this.selectedField.options
          .sort((a, b) => {
            return a.sortOrder > b.sortOrder ? 1 : -1;
          })
          .map((opt) => {
            return {
              value: opt.key,
              label: opt.title,
            };
          });

        const isSelectedValueExists = this.valueOptions.find((option) => option.value === criteria.value) !== undefined;
        if (!isSelectedValueExists) {
          criteria.value = null;
        }
      }

      if (criteria.target !== 'Value') {
        const isSelectedTargetExists =
          targetFields.find(
            (field) => field.fieldId === criteria.targetFieldId && field.fieldGroup === criteria.targetFieldGroup,
          ) !== undefined;
        if (!isSelectedTargetExists) {
          criteria.value = null;
          criteria.targetFieldId = null;
          criteria.targetFieldGroup = null;
          this._selectedTargetField = null;
        }
      }
    }
  }

  private checkState(criteria: DecisionCriteria): void {
    const selectedState = this.stateOptions.find((state) => state.key === criteria.state);

    if (!selectedState) {
      return;
    }

    this.showValue = selectedState.isValueRequired ? true : false;

    if (selectedState.targetOptions?.length) {
      this.showTarget = true;

      this.showValue = criteria.target === 'Value';
      this.showTargetField = !this.showValue;
    } else {
      this.showTarget = false;
    }
  }

  private areFieldTypesEqual(field1: WorkflowField, field2: WorkflowField): boolean {
    return field1.type === field2.type;
  }
}
