<div class="modal-header">
  <button
    [appFocusable]
    type="button"
    class="close-btn"
    aria-label="Close"
    (click)="activeModal.dismiss()"
  >
    <img
      appAssetSrc="assets/svg/common/cross.svg"
      alt="{{ 'common.buttons.close' | translate }}"
    />
  </button>
</div>

<div class="modal-body">
  <form
    [formGroup]="form"
    class="workflow-step-modal_form"
    #ngForm="ngForm"
    (ngSubmit)="save()"
  >
    <div class="workflow-step-modal_header">
      <div>
        <span>{{ 'common.labels.step' | translate }}: </span>
        <span>{{ 'approvalModal.title' | translate }}</span>
      </div>
      <div>
        <div>{{ 'approvalModal.titleMsg' | translate }}</div>
        <button
          type="submit"
          class="btn btn-primary submit-btn"
        >
          {{ 'common.buttons.save&close' | translate }}
        </button>
      </div>
    </div>

    <div class="workflow-step-modal_scroll-area">
      <scrollable-area [maxHeight]="maxScrollAreaHeight">
        <div
          class="form-group"
          [ngClass]="{
            'has-error': ngForm.submitted && form.get('name').invalid,
          }"
        >
          <label
            class="required"
            for="stepName"
            >{{ 'workflows.controls.stepName.label' | translate }}</label
          >
          <input
            formControlName="name"
            id="stepName"
            placeholder="{{ 'workflows.controls.stepName.placeholder' | translate }}"
          />
          <form-error-messages
            [showErrors]="ngForm.submitted"
            [control]="form.get('name')"
          >
          </form-error-messages>
        </div>

        <div
          class="form-group"
          [ngClass]="{
            'has-error': ngForm.submitted && form.get('description').invalid,
          }"
        >
          <label
            class="required"
            for="description"
            >{{ 'workflows.controls.stepDescription.label' | translate }}</label
          >
          <textarea
            formControlName="description"
            id="description"
            placeholder="{{ 'workflows.controls.stepDescription.placeholder' | translate }}"
          >
          </textarea>
          <form-error-messages
            [showErrors]="ngForm.submitted"
            [control]="form.get('description')"
          >
          </form-error-messages>
        </div>

        <div
          class="form-group"
          [ngClass]="{
            'has-error': ngForm.submitted && form.get('approverInstructions').invalid,
          }"
        >
          <label
            class="required"
            for="text"
            >{{ 'approvalModal.controls.instructions.label' | translate }}</label
          >
          <rich-text-editor
            formControlName="approverInstructions"
            placeholder=""
            id="text"
            [customDropdown]="true"
            [workflowType]="workflowType"
          >
          </rich-text-editor>
          <form-error-messages
            [showErrors]="ngForm.submitted"
            [control]="form.get('approverInstructions')"
          >
          </form-error-messages>
        </div>

        <div
          class="form-group"
          [ngClass]="{
            'has-error': ngForm.submitted && form.get('approvers').invalid,
          }"
        >
          <label
            class="required"
            for="text"
            >{{ 'approvalModal.controls.approvers.label' | translate }}</label
          >

          <recipient-selector
            mode="multiple"
            [selectedUsers]="selectedApprovers"
            (selectedUsersChange)="handleApproversChange($event)"
          />

          <form-error-messages
            [showErrors]="ngForm.submitted"
            [customErrors]="{
              required: 'approvalModal.controls.approvers.required' | translate,
            }"
            [control]="form.get('approvers')"
          >
          </form-error-messages>
        </div>
      </scrollable-area>
    </div>
  </form>
</div>
